import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const moreLinks = [
  { text: "Twitter", url: "https://twitter.com/vivendalabs" },
  // { text: "LensFlare", url: "https://twitter.com/vivendalabs" },
  // { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
  // {
  //   text: "Documentation",
  //   url: "https://gatsbyjs.com/docs/",
  // },
  // { text: "Github", url: "https://github.com" },
  // { text: "Issues", url: "https://github.com/gatsbyjs/gatsby/issues" },
]

// const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/vivenda-logo-tmp.png"
        loading="eager"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)` }}
      />
      <h1>Vivenda Labs</h1>
    </div>
    <div className={styles.textCenter}>
      <p
        style={{
          textAlign: "left",
        }}
      >
        Vivenda is a decentralized finance (DeFi) platform that enables
        membership organizations to increase patron engagement and revenue by
        leveraging our research, experiments, products, and expert guidance.
        Vivenda's easy to use, self-service, open source NFT and DeFi tools let
        donors support their favorite causes while preserving assets.
        Organizations use Vivenda to create and distribute enhanced NFTs linked
        to novel DeFi strategies and a decentralized social graph.
      </p>
    </div>
    {moreLinks.map((link, i) => (
      <React.Fragment key={link.url}>
        {/* <a href={`${link.url}${utmParameters}`}>{link.text}</a> */}
        <a href={link.url}>{link.text}</a>
        {i !== moreLinks.length - 1 && <> · </>}
      </React.Fragment>
    ))}
  </Layout>
)

export default IndexPage
